<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-brandprofile', params: { accountId: this.$route.params.accountId } }">BrandProfiles</router-link>
                </p>
                </v-col>
            </v-row>
            <!-- <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ brandProfile.name }}</h1>
                < ! - - <h1 class="text-caption font-weight-light text-center">{{ brandProfile.status }}</h1> - - >
                </v-col>
            </v-row> -->
            <v-row justify="center" class="py-5 px-10" v-if="brandProfile">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card>
                        <v-app-bar color="teal darken-2" dark flat dense>
                            <v-app-bar-title>BrandProfile</v-app-bar-title>
                        </v-app-bar>
                        <v-card-text>

                        <p class="text-overline mb-0 mt-2">BrandProfile alias</p>
                        <p class="mb-0 pb-0">
                            {{ brandProfile.alias }}
                        </p>

                        <p class="text-overline mb-0 mt-8">BrandProfile status</p>
                        <template v-if="brandProfile.status === 'new'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                            Not verified
                        </p>
                        <p class="mb-0 pb-0 text-caption">
                            You must prove ownership of the BrandProfile before it can be used with Emplus.
                        </p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-verify-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.$route.params.brandprofileId } }">Verify BrandProfile ownership</router-link>
                        </p>
                        </template>
                        <template v-if="brandProfile.status === 'pending'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                            Verification in progress
                            <!-- TODO: show the verification code here that the user must set in registrar's dns; also maybe a dialog with extra help, or link to public help page -->
                        </p>
                        <p class="mb-0 pb-0 text-caption">
                            You must create a DNS record with this code to prove ownership of the BrandProfile.
                            <!-- TODO: link to brandprofile for verification ??? or would that be in the account-verify-brandprofile page ??? or just talk about that process here ??? or in the account-verify-brandprofile page ??? -->
                        </p>
                        </template>
                        <template v-if="brandProfile.status === 'verified'">
                        <p class="mb-0 pb-0">
                            <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                            Verified
                        </p>
                        <p class="mb-0 pb-0" v-if="brandProfile.link && brandProfile.link.subscribe">
                            <a :href="brandProfile.link.subscribe" target="_blank">Subscribe link</a>
                        </p>
                        <p class="mb-0 pb-0" v-if="brandProfile.link && brandProfile.link.unsubscribe">
                            <a :href="brandProfile.link.unsubscribe" target="_blank">Unsubscribe link</a>
                        </p>
                        <!-- <p class="mb-0 pb-0 text-caption">
                            You must create a DNS record with this code to prove ownership of the BrandProfile.
                        </p> -->
                        </template>

                        <!-- TODO: rename this section from 'DNS' to "anti-spam" settings for DMARC/DKIM/SPF, and here we can report on status of BrandProfile if it's propertly configured for emplus or not... so on server a "check BrandProfile" API with items like 'DMARC', 'DKIM', 'SPF', etc. so each item can be checked separately , and we'd call them all from here adn report the result (for each one, checkbox or not) -->

                        <!-- <p class="text-overline mb-0 mt-8">DNS</p>
                        <p class="mb-0 pb-0">
                            <router-link :to="{ name: 'account-edit-dns', params: { accountId: this.$route.params.accountId, brandProfile: this.$route.params.brandprofileId } }">Edit DNS records</router-link>
                        </p> -->

                        <p class="text-overline mb-0 mt-8">Other</p>
                        <p class="mb-0 pb-0">
                            <!-- TODO: archive/unarchive the BrandProfile, so it doesn't even show up for admin unless they ask to look at archived BrandProfiles -->
                            <!-- <v-btn color="white" class="red--text">Delete BrandProfile</v-btn> -->
                            <router-link :to="{ name: 'account-delete-brandprofile', params: { accountId: this.$route.params.accountId, brandProfile: this.$route.params.brandprofileId } }">How to delete this BrandProfile</router-link>
                        </p>

                        <template v-if="isPermitServiceAdmin">
                        <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                        <p class="mb-0 pb-0">
                            <!-- <router-link :to="{ name: 'service-admin' }">Manage Emplus</router-link> -->
                            <!-- Edit BrandProfile status -->
                            <v-btn text color="teal darken-2" class="px-0" @click="editBrandProfileStatus('verified')" v-if="brandProfile.status !== 'verified'">Verify BrandProfile</v-btn>
                            <v-btn text color="teal darken-2" class="px-0" @click="editBrandProfileStatus('new')" v-if="brandProfile.status === 'verified'">Suspend BrandProfile</v-btn>
                        </p>
                        </template>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/brandProfile-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        brandProfile: null,
        error: null,
        whois: null,
        whoisDialog: false,
        nameserverList: null,
        status: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        registrarName() {
            return this.whois?.info?.Registrar?.join(' / ') ?? 'Unknown';
        },
        registrarURL() {
            try {
                const list = this.whois?.info?.['Registrar URL'];
                if (Array.isArray(list) && list.length > 0) {
                    const inputURL = new URL(list[0]);
                    return inputURL.toString();
                }
                return null;
            } catch (err) {
                return null;
            }
        },
        registrarDnsNameservers() {
            return this.whois?.info?.['Name Server'] ?? [];
        },
        // TODO: move this getter to vuex so we don't have to redefine it everywere... we'll just use mapGetters
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isViewReady() {
            return this.account !== null && this.brandProfile !== null;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadBrandProfile() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadBrandProfile: true });
                const response = await this.$client.account(this.$route.params.accountId).brandProfile.get({ id: this.$route.params.brandprofileId });
                console.log(`brandProfile/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.brandProfile = response;
                } else {
                    // TODO: redirect back to BrandProfile list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load BrandProfile', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadBrandProfile: false });
            }
        },
        async editBrandProfileStatus(newValue) {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { editBrandProfileStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).brandProfileStatus.edit({ brandprofile_id: this.brandProfile?.id }, { status: newValue });
                console.log(`brandProfile/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.$set(this.brandProfile, 'status', newValue);
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'BrandProfile status updated', message: `New status: ${newValue}` });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update BrandProfile status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update BrandProfile status' });
            } finally {
                this.$store.commit('loading', { editBrandProfileStatus: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadBrandProfile();
    },
};
</script>
